import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Row, Col, Spinner } from 'react-bootstrap'
import { message } from 'antd'

import { storeServiceReview } from '../services'
import { handleError } from '../../../helpers'
import FormatVersionSelect from '../../FormatVersions/FormatVersionSelect'

export default function NewServiceReviewModal({ visible, onClose, servicePlanId, businessScope, reload }) {
	const { register, handleSubmit, formState: { errors }, setValue } = useForm({
		defaultValues: {
			service_plan_id: servicePlanId,
			review_specs: {
				has_security_actions: 1,
				has_security_conditions: 1,
				has_security_additionals: 0,
				has_tecnical_support: 0,
				results_if_compliant: (businessScope === 'pruebas') ? 'Se verifico que en los tanques que pasaron la prueba: \n- Se alcanzo la presión. \n- Se mantuvo la presión. \nSe verificó que en las tuberías que pasaron la prueba: \n- Se alcanzo la presión. \n- Se mantuvo la presión. \n- La decisión de conformidad será declarada por el Director Técnico una vez se revisen todos los registros del proceso.' : '',
				results_if_not_compliant: (businessScope === 'pruebas') ? 'Si alguno de los items presenta resultado con no cumplimiento, El Cliente tendrá que realizar los correctivos necesarios y solicitar un proceso de inspección para evaluar la conformidad del tanque o tubería. La decisión de no conformidad será declarada por el Director Técnico. El tanque o la tubería con no cumplimiento no se incluirá dentro de la Declaración de la conformidad.' : '',
				final_declaration: (businessScope === 'pruebas') ? 'Una vez finalizado el servicio se da constancia que la instalación se entrega y es recibida a satisfacción por el Cliente, en buenas condiciones y  con los equipos en funcionamiento.' : '',
			}
		}
	})
	const [loading, setLoading] = useState(false)

	const onSubmit = values => {
		setLoading(true)
		storeServiceReview(values)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Iniciar Acta de Inspección</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Row>
							<Form.Group as={Col}>
								<Form.Label>Versión <span className='text-danger'>*</span></Form.Label>
								<FormatVersionSelect 
									keyword="serviceReview"
									setValue={setValue}
									businessScope={businessScope}
								/>
								{ errors.format_version_id && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>Fecha Vigencia <span className='text-danger'>*</span></Form.Label>
								<Form.Control {...register('expire_at')} disabled />
							</Form.Group>
						</Row>
						<Form.Control type="hidden" {...register('review_specs', { required: true })} />
						<Button color="primary" type="submit" disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Iniciar Acta de Inspección
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}