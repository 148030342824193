import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { message, Table, Tooltip, Divider, Popconfirm } from 'antd'
import moment from 'moment'

import Pagination from '../../../components/Pagination'
import ServiceOfflineStatus from '../../Offline/partials/ServiceOfflineStatus'

import { deleteService, updateService } from '../services'
import { handleError } from '../../../helpers'

export default function ServicesTable({ services, reload, pagination, setPagination, businessScope }) {
	const currentUser = useSelector(state => state.auth.user)
	const { isConnected } = useSelector(state => state.offline)
	
	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			render: t => <Link to={`/services/${t}`}>#{t}</Link>
		},
		{
			title: 'Fecha de Inicio',
			dataIndex: 'start_at',
			render: t => moment(t).format('DD-MMM-YYYY').toUpperCase(),
			width: '120px'
		},
		{
			title: 'Tipo',
			dataIndex: 'type',
			hidden: businessScope === 'pruebas'
		},
		{
			title: 'Empresa',
			dataIndex: 'proposal',
			render: t => t.inquiry.branch_spec.branch.company.name
		},
		{
			title: 'Instalación',
			dataIndex: 'proposal',
			render: t => t.inquiry.branch_spec.branch.name
		},
		{
			title: 'Tipo Instalación',
			dataIndex: 'proposal',
			render: t => t.inquiry.branch_spec.branch.instalation_type_name
		},
		{
			title: 'Propuesta',
			dataIndex: 'proposal',
			render: t => t.sku,
			width: '140px',
		},
		{
			title: 'Inspector',
			dataIndex: 'service_inspector',
			render: t => t ? t.user_name : <em>Sin inspector asignado</em>
		},
		{
			title: 'Offline',
			render: (t, r) => <ServiceOfflineStatus service={r} />,
			width: '175px'
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Perfil del Servicio">
						<Link to={`/services/${r.id}`}>
							<FontAwesomeIcon className="text-link" icon={faSearch} />
						</Link>
					</Tooltip>
					{ currentUser.role === 'admin' && isConnected && !r.canceled_at && (
						<React.Fragment>
							<Divider type="vertical" />
							<Tooltip title="Cancelar Servicio">
								<Popconfirm 
									title="¿Desea cancelar el servicio?"
									okText="Cancelar"
									cancelText="Cerrar"
									onConfirm={() => handleCancel(r.id)}
									okButtonProps={{ danger:true }}
								>
									<FontAwesomeIcon className="text-link" icon={faTrashAlt} />
								</Popconfirm>
							</Tooltip>
						</React.Fragment>
					)}
					{ currentUser.role === 'admin' && isConnected && !r.service_inspector && (
						<React.Fragment>
							<Divider type="vertical" />
							<Tooltip title="Eliminar Servicio">
								<Popconfirm 
									title="¿Desea eliminar el servicio?"
									okText="Eliminar"
									cancelText="Cancelar"
									onConfirm={() => handleDelete(r.id)}
									okButtonProps={{ danger:true }}
								>
									<FontAwesomeIcon className="text-link" icon={faTrash} />
								</Popconfirm>
							</Tooltip>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	].filter(item => !item.hidden)

	const handleDelete = id => {
		deleteService(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const handleCancel = id => {
		updateService(id, { canceled_at: moment().format('YYYY-MM-DD HH:mm:ss') })
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}
	
	return (
		<React.Fragment>
			<Table 
				dataSource={services}
				loading={!services}
				columns={columns}
				rowKey={r => r.id}
				size="small"
				pagination={!pagination}
				className="mb-15"
			/>
			{ pagination && (
				<Pagination
					reload={reload}
					pagination={pagination}
					updatePagination={setPagination}		
				/>
			)}
		</React.Fragment>
	)
}